import { useTranslation } from 'react-i18next';
import styles from './highlights.module.scss';
import { GoldButton, ImageTextOverlay } from '@orascom/common-components';
import { CUSTOM_PAGES_CONTEXT } from '../../contexts/custom-pages';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../assets/images/welcome-to-orascom.webp';
import ReachUs from '../../components/common/reach-us/reach-us';
import { ROUTES } from '../../api/routes';
import ExploreIndicator from '../../components/common/explore-indicator/explore-indicator';
import AboutOrascom from '../../components/common/about-orascom/about-orascom';
import { v4 as uuidv4 } from 'uuid';

const Highlights = () => {
  const { t } = useTranslation();
  const { customPages } = useContext(CUSTOM_PAGES_CONTEXT);
  const { pageSlug } = useParams();
  const foundPage = customPages?.find((page) => page.slug === pageSlug);
  const sortedSections = foundPage?.sections.sort((a, b) => a.order - b.order);

  return (
    <div className={styles['container']}>
      <div className={styles['banner']}>
        <ImageTextOverlay
          image={logo}
          title={t('highlights')}
          subTitle={foundPage?.name ?? ''}
          gradient
        />
      </div>
      <div className={`${styles['intro']} container`}>
        <p className={styles['description']}>{foundPage?.description}</p>
        <ExploreIndicator title={t('learnMore')} />
      </div>
      <div>
        {sortedSections?.map((section, index) => {
          const showAbout = section.name && (section.image || section.video);
          return showAbout ? (
            <AboutOrascom
              key={uuidv4()}
              subTitle={section.name}
              description={section.description}
              videoUrl={section.video}
              aboutImage={section.image}
              showAboutButton={false}
              customPage={true}
              imageFirst={index % 2 === 0}
            />
          ) : (
            <div key={uuidv4()} className={`${styles['intro']} container`}>
              <h2>{section.name}</h2>
              <p className={styles['description']}>{section.description}</p>
            </div>
          );
        })}
      </div>
      <ReachUs
        description={t('weWouldLoveToShareIdeas')}
        primaryButton={
          <GoldButton asLink to={ROUTES.ContactUs.path}>
            {t('getInTouch')}
          </GoldButton>
        }
      />
    </div>
  );
};

export default Highlights;
