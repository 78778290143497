import React from 'react';
import {
  CountriesEnum,
  UnitDetails,
  UnitPaymentTerms,
} from '../../../api-interfaces/src';
import { PDFDocument, PDFPage } from 'pdf-lib';
import html2canvas from 'html2canvas';
import { createRoot } from 'react-dom/client';
import SalesOfferPaymentCalculator from '../../../common-components/src/lib/sales-offer-payment-calculator/sales-offer-payment-calculator';
import SalesOfferFloorPlan from '../../../common-components/src/lib/sales-offer-floor-plan/sales-offer-floor-plan';
import SalesOfferInstallmentPlan from '../../../common-components/src/lib/sales-offer-installment-plan/sales-offer-installment-plan';
import SalesOfferPaymentMontengroCalculator from '../../../common-components/src/lib/sales-offer-payment-calculator/sales-offer-montenegro-calculator';
import SalesOfferInstallmentPlanMontenegro from '../../../common-components/src/lib/sales-offer-installment-plan/sales-offer-installment-plan-montenegro';
/**
 *
 * @param unit
 * @returns image of the header of floor plan page in sales offer
 */
const captureFloorPlanToImage = async (unit: UnitDetails) => {
  try {
    const container = document.createElement('div');
    document.body.appendChild(container);
    container.style.width = '1760px';
    const root = createRoot(container);
    root.render(<SalesOfferFloorPlan unit={unit} />);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL('image/png');
    document.body.removeChild(container);
    return imgData;
  } catch (error) {
    console.error('Error capturing component to image:', error);
    return null;
  }
};
/**
 *
 * @param selectedPaymentTerms
 * @returns image of payment calculator card in payment plan page
 */
const capturePaymentCalculatorToImage = async (
  selectedPaymentTerms: UnitPaymentTerms,
  country: string
) => {
  try {
    const container = document.createElement('div');
    document.body.appendChild(container);
    container.style.width = '640px';

    const root = createRoot(container);
    if (country === CountriesEnum.MONTENEGRO) {
      root.render(
        <SalesOfferPaymentMontengroCalculator
          selectedPaymentTerms={selectedPaymentTerms}
        />
      );
    } else {
      root.render(
        <SalesOfferPaymentCalculator
          selectedPaymentTerms={selectedPaymentTerms}
        />
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL('image/png');
    document.body.removeChild(container);
    return imgData;
  } catch (error) {
    console.error('Error capturing component to image:', error);
    return null;
  }
};
/**
 *
 * @param selectedPaymentTerms
 * @returns image of payment installments card in payment plan page
 */

const capturePaymentInstallmentsToImage = async (
  selectedPaymentTerms: UnitPaymentTerms,
  country: string
) => {
  try {
    const container = document.createElement('div');
    document.body.appendChild(container);
    container.style.width = '864px';
    const root = createRoot(container);
    if (country === CountriesEnum.MONTENEGRO) {
      root.render(
        <SalesOfferInstallmentPlanMontenegro
          selectedPaymentTerms={selectedPaymentTerms}
        />
      );
    } else {
      root.render(
        <SalesOfferInstallmentPlan
          selectedPaymentTerms={selectedPaymentTerms}
        />
      );
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL('image/png');
    document.body.removeChild(container);
    return imgData;
  } catch (error) {
    console.error('Error capturing component to image:', error);
    return null;
  }
};
/**
 *
 * @param pdfDoc
 * @param unit
 * @param pageNumber
 * inserts the image of floor plan header in the sales offer pdf
 */
async function addFloorPlanHeadingToSalesOffer(
  pdfDoc: PDFDocument,
  unit: UnitDetails,
  pageNumber: number
) {
  const pages = pdfDoc.getPages();
  const floorPlanHeading = await captureFloorPlanToImage(unit);
  if (floorPlanHeading) {
    const base64Data = floorPlanHeading.split(',')[1];
    const uint8Array = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );

    const pdfImage = await pdfDoc.embedPng(uint8Array);
    if (pageNumber < pdfDoc.getPageCount()) {
      const paymentPlanPage = pages[pageNumber - 1];

      paymentPlanPage.drawImage(pdfImage, {
        x: 40,
        y: 380,
        width: 884,
        height: 120,
      });
    }
  }
}
/**
 *
 * @param pdfDoc
 * @param paymentPlan
 * @param pageNumber
 * inserts the image of payment installments plan in the sales offer pdf
 */
async function addPaymentInstallmentsToSalesOffer(
  pdfDoc: PDFDocument,
  paymentPlan: UnitPaymentTerms,
  pageNumber: number,
  country: string
) {
  const pages = pdfDoc.getPages();
  const PaymentInstallmentsImg = await capturePaymentInstallmentsToImage(
    paymentPlan,
    country
  );
  if (PaymentInstallmentsImg) {
    const base64Data = PaymentInstallmentsImg.split(',')[1];
    const uint8Array = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );

    const pdfImage = await pdfDoc.embedPng(uint8Array);
    if (pageNumber < pdfDoc.getPageCount()) {
      const paymentPlanPage = pages[pageNumber - 1];
      if (country === CountriesEnum.MONTENEGRO) {
        const height =
          90 + (paymentPlan.payment_plan_details?.length ?? 0) * 10;
        let y;
        if (
          paymentPlan.payment_plan_details &&
          paymentPlan.payment_plan_details.length < 4
        ) {
          y = 150;
        } else if (
          paymentPlan.payment_plan_details &&
          paymentPlan.payment_plan_details.length < 7
        ) {
          y = 100;
        } else {
          y = 350;
        }
        paymentPlanPage.drawImage(pdfImage, {
          x: 485,
          y,
          width: 435,
          height,
        });
      } else {
        paymentPlanPage.drawImage(pdfImage, {
          x: 485,
          y: 42,
          width: 435,
          height: 483,
        });
      }
    }
  }
}
/**
 *
 * @param pdfDoc
 * @param paymentPlan
 * @param pageNumber
 * inserts the image of payment calculator in the sales offer pdf
 */
async function addPaymentPlanToSalesOffer(
  pdfDoc: PDFDocument,
  paymentPlan: UnitPaymentTerms,
  pageNumber: number,
  country: string
) {
  const pages = pdfDoc.getPages();
  const paymentCalculatorImg = await capturePaymentCalculatorToImage(
    paymentPlan,
    country
  );
  if (paymentCalculatorImg) {
    const base64Data = paymentCalculatorImg.split(',')[1];
    const uint8Array = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const pdfImage = await pdfDoc.embedPng(uint8Array);
    if (pageNumber < pdfDoc.getPageCount()) {
      const paymentPlanPage = pages[pageNumber - 1];
      paymentPlanPage.drawImage(pdfImage, {
        x: 40,
        y: 42,
        width: 320,
        height: 440,
      });
    }
  }
}

/**
 *
 * @param pdfDoc
 * @param image
 * @param floorPlanPage
 * @param config
 * takes the image of the floor plan and inserts it in correct coordinates in the floor plan page
 */
async function addFloorPlanImageToSalesOffer(
  pdfDoc: PDFDocument,
  image: string,
  floorPlanPage: PDFPage,
  config: { x: number; y: number; width: number; height: number }
) {
  if (!image) {
    return;
  }
  const pngImageBytes = await fetch(image).then((res) => res.arrayBuffer());
  if (pngImageBytes.byteLength === 0) {
    return;
  }
  const pngImage = await pdfDoc.embedPng(pngImageBytes);
  floorPlanPage.drawImage(pngImage, config);
}
/**
 *
 * @param title
 * @param floorPlanPage
 * @param config
 * takes the title of each floor plan and inserts it in correct coordinates in the floor plan page
 */
async function addFloorPlanTitleToSalesOffer(
  title: string,
  floorPlanPage: PDFPage,
  config: { x: number; y: number; size: number }
) {
  floorPlanPage.drawText(title, config);
}

/**
 *
 * @param pdfDoc
 * @param unit
 * configures layout of floor plan images based on the number of images that will be inserted
 */
async function addFloorPlanToSalesOffer(
  pdfDoc: PDFDocument,
  unit: UnitDetails
) {
  const pages = pdfDoc.getPages();
  if (unit.project.floorplan_page_on_sales_offer < pdfDoc.getPageCount()) {
    const floorPlanPage = pages[unit.project.floorplan_page_on_sales_offer - 1];
    let x, y, titlePosition, titlePositionY;
    switch (unit.masterplans.length) {
      case 1:
        await addFloorPlanImageToSalesOffer(
          pdfDoc,
          unit.masterplans[0].image,
          floorPlanPage,
          {
            x: (floorPlanPage.getWidth() - 400) / 2,
            y: 30,
            width: 400,
            height: 350,
          }
        );

        await addFloorPlanTitleToSalesOffer(
          unit.masterplans[0].title.toUpperCase(),
          floorPlanPage,
          {
            x: (floorPlanPage.getWidth() - 400) / 2 + 150,
            y: 30,
            size: 15,
          }
        );
        break;
      case 2:
        x = (floorPlanPage.getWidth() - 810) / 2;
        titlePosition = 230;
        for (const masterplan of unit.masterplans) {
          await addFloorPlanImageToSalesOffer(
            pdfDoc,
            masterplan.image,
            floorPlanPage,
            {
              x,
              y: 40,
              width: 400,
              height: 300,
            }
          );
          await addFloorPlanTitleToSalesOffer(
            masterplan.title.toUpperCase(),
            floorPlanPage,
            {
              x: titlePosition,
              y: 30,
              size: 15,
            }
          );
          x += 410;
          titlePosition = 640;
        }
        break;
      case 3:
        x = (floorPlanPage.getWidth() - 770) / 2;
        titlePosition = 196;
        for (const masterplan of unit.masterplans) {
          await addFloorPlanImageToSalesOffer(
            pdfDoc,
            masterplan.image,
            floorPlanPage,
            {
              x,
              y: 80,
              width: 250,
              height: 250,
            }
          );
          await addFloorPlanTitleToSalesOffer(
            masterplan.title.toUpperCase(),
            floorPlanPage,
            {
              x: titlePosition,
              y: 60,
              size: 15,
            }
          );
          x += 260;
          titlePosition += 260;
        }
        break;
      case 4:
        x = (floorPlanPage.getWidth() - 510) / 2;
        y = 40;
        titlePosition = 326;
        titlePositionY = 30;
        for (let i = 0; i < unit.masterplans.length; i++) {
          await addFloorPlanImageToSalesOffer(
            pdfDoc,
            unit.masterplans[i].image,
            floorPlanPage,
            { x, y, width: 250, height: 150 }
          );
          await addFloorPlanTitleToSalesOffer(
            unit.masterplans[i].title.toUpperCase(),
            floorPlanPage,
            {
              x: titlePosition,
              y: titlePositionY,
              size: 10,
            }
          );
          y += 165;
          titlePositionY += 160;
          if (i == 1) {
            x += 260;
            y = 40;
            titlePositionY = 30;
            titlePosition += 260;
          }
        }
        break;
      default:
        x = (floorPlanPage.getWidth() - 770) / 2;
        y = 40;
        titlePosition = 196;
        titlePositionY = 30;
        for (let i = 0; i < unit.masterplans.length; i++) {
          await addFloorPlanImageToSalesOffer(
            pdfDoc,
            unit.masterplans[i].image,
            floorPlanPage,
            { x, y, width: 250, height: 150 }
          );
          await addFloorPlanTitleToSalesOffer(
            unit.masterplans[i].title.toUpperCase(),
            floorPlanPage,
            {
              x: titlePosition,
              y: titlePositionY,
              size: 10,
            }
          );
          y += 165;
          titlePositionY += 165;
          if (i == 1 || i == 3) {
            x += 260;
            y = 40;
            titlePositionY = 30;
            titlePosition += 260;
          }
        }
        break;
    }
  }
}
/**
 *
 *
 * @param {PDFDocument} pdfDoc
 * @param {UnitDetails} unit
 * Inserts the unit gallery images into their corresponding pages
 */
async function addImageGalleryToSalesOffer(
  pdfDoc: PDFDocument,
  unit: UnitDetails
) {
  const pages = pdfDoc.getPages();
  const pagesArray = unit.project.unit_images_pages_on_sales_offer
    ? unit.project.unit_images_pages_on_sales_offer.split(',')
    : [];
  for (let index = 0; index < pagesArray.length; index++) {
    const image = unit.gallery[index];
    const imagePageNumber = Number(pagesArray[index]);

    if (imagePageNumber <= pdfDoc.getPageCount()) {
      const pngImageBytes = await fetch(image).then((res) => res.arrayBuffer());
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const imagePage = pages[imagePageNumber - 1];

      // Calculate scaled dimensions to maintain aspect ratio
      const aspectRatio = pngImage.width / pngImage.height;
      const maxWidth = imagePage.getWidth() - 78;
      const maxHeight = maxWidth / aspectRatio;

      // Calculate position to center the image on the page
      const x = (imagePage.getWidth() - maxWidth) / 2;
      const y = (imagePage.getHeight() - maxHeight) / 2;

      // Draw the image with the calculated dimensions and position
      imagePage.drawImage(pngImage, {
        x,
        y,
        width: maxWidth,
        height: maxHeight,
      });
    }
  }
}
/**
 *
 * @param unit
 * @param selectedPaymentTerms
 * calls the functions to insert all elements in the pdf and downloads it
 */
export async function downloadSalesOffer(
  unit: UnitDetails,
  selectedPaymentTerms: UnitPaymentTerms
) {
  if (!unit?.project.sales_offer) {
    return;
  }

  const salesOfferUrl = new URL(unit.project.sales_offer);
  // make sure we are getting non cached response
  salesOfferUrl.searchParams.set('cache_bust', Date.now().toString());

  const response = await fetch(salesOfferUrl.toString(), {
    cache: 'no-store',
  });

  if (!response.ok) {
    console.error('Failed to fetch the PDF:', response.statusText);
    return;
  }

  const pdfBytes = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);
  await addPaymentPlanToSalesOffer(
    pdfDoc,
    selectedPaymentTerms,
    unit.project.paymentplan_page_on_sales_offer,
    unit.project.destination.country.slug
  );
  await addPaymentInstallmentsToSalesOffer(
    pdfDoc,
    selectedPaymentTerms,
    unit.project.paymentplan_page_on_sales_offer,
    unit.project.destination.country.slug
  );

  await addFloorPlanHeadingToSalesOffer(
    pdfDoc,
    unit,
    unit.project.floorplan_page_on_sales_offer
  );

  if (unit.masterplans.length > 0) {
    await addFloorPlanToSalesOffer(pdfDoc, unit);
  }

  if (
    unit.gallery.length > 0 &&
    unit.project.unit_images_pages_on_sales_offer
  ) {
    await addImageGalleryToSalesOffer(pdfDoc, unit);
  }

  const modifiedPdfBytes = await pdfDoc.save();
  const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  const salesOfferName = 'Unit ' + unit.name + ' sales-offer.pdf';
  a.download = salesOfferName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function downloadSalesOfferOman(url: string, unitName: string) {
  const link = document.createElement('a');
  link.href = url;
  const salesOfferName = 'Unit ' + unitName + ' sales-offer.pdf';
  link.setAttribute('download', salesOfferName);
  document.body.appendChild(link);
  link.click();
}
