/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import styles from './login-form.module.scss';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { GoldButton } from '../../components/common/button/button';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import ErrorIcon from '../../assets/icons/error.svg';
import InfoIcon from '../../assets/icons/info-grey.svg';
import { SignUpFormInterface } from '../../definitions/interfaces/authentication.interface';
import { Authentication as AuthenticationUtil } from '../../utils/authentication.utils';
import { isEmpty } from '../../utils/common.utils';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { v4 as uuidv4 } from 'uuid';
import { validatePhone } from '@orascom/utils';

export interface SignUpFormProps {
  onFinish: (values: SignUpFormInterface) => void;
  values?: SignUpFormInterface;
}

export function LoginForm(props: Readonly<SignUpFormProps>) {
  const { t } = useTranslation();
  const LoginFormSchema = Yup.object().shape({
    phone: Yup.string().required(t('pleaseEnterPhoneNumber')),
  });

  const [loginErrors, setLoginErrors] = useState([]);

  const SendOTP = (
    values: SignUpFormInterface,
    actions?: FormikHelpers<SignUpFormInterface>
  ) => {
    const phoneNumberWithCountryCode = `+${values.code}${parseInt(
      values.phone
    )}`;

    AuthenticationUtil.signInOnFirebase(phoneNumberWithCountryCode)
      .then(() => {
        actions?.setSubmitting(false);
        props.onFinish({
          phone: values.phone,
          code: values.code,
        });
      })
      .catch((errors) => {
        console.warn(errors);
        setLoginErrors(errors);
        actions?.setSubmitting(false);
      });
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('login')}</h1>
      <Formik
        initialValues={{
          phone: props.values?.phone ?? '',
          code: props.values?.code ?? '20',
        }}
        validationSchema={LoginFormSchema}
        onSubmit={SendOTP}
      >
        {({ errors, touched, setFieldValue, values, isSubmitting }) => (
          <Form>
            <div className="input-wrapper">
              <label htmlFor="phone">{t('phoneNumber')}*</label>
              <div className={styles['phone-wrapper']}>
                <PhoneInput
                  countryCodeEditable={false}
                  inputProps={{
                    name: 'code',
                  }}
                  country={'eg'}
                  onChange={(phone) => {
                    setFieldValue('code', phone);
                    setFieldValue('phone', '');
                  }}
                  enableSearch={true}
                  excludeCountries={['il']}
                  value={values.code}
                />
                <Field
                  name="phone"
                  type="tel"
                  validate={() => validatePhone(values.phone, values.code)}
                  className={errors.phone && touched.phone ? 'error' : ''}
                  placeholder="123 456 7890"
                />
              </div>
              {errors.phone && touched.phone ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.phone}
                </p>
              ) : null}
              <p className={styles['otp-disclaimer']}>
                <img src={InfoIcon} role="presentation" alt="" />
                {t('weWillSendOTP')}
              </p>
            </div>

            <div className={styles['send-code']}>
              <GoldButton
                className={styles['contact-btn']}
                type="submit"
                id="sign-in-button"
                disabled={isSubmitting}
              >
                {t('login')}
              </GoldButton>
              <p>
                <span>{t('doNotHaveAccount')}</span>
                <Link to={ROUTES.Register.path}>{t('signUp')}</Link>
              </p>
            </div>
            {!isEmpty(loginErrors) && (
              <div className={styles['errors-container']}>
                {loginErrors.map((error) => (
                  <span key={uuidv4()}>{error}</span>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;
