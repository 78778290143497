import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Country as CountryUtilities } from '../../utils/country.utils';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import { AboutOrascom } from '../../components/common/about-orascom/about-orascom';
import CountryDestinations from '../../components/country-destinations/country-destinations';
import { ImageTextOverlay, Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { ExploreIndicator } from '../../components/common/explore-indicator/explore-indicator';
import ReachUs from '../../components/common/reach-us/reach-us';
import { GoldButton } from '../../components/common/button/button';
import {
  UnitInterface,
  CountryInfo as CountryInfoInterface,
  FiltersEnums,
} from '@orascom/api-interfaces';
import PropertyCardsSwiper from '../../components/common/property-cards-swiper/property-cards-swiper';
import Modal from 'libs/common-components/src/lib/modal/modal';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import CompareButton from '../../components/common/compare-button/compare-button';
import { isEmpty } from '../../utils/common.utils';
import styles from './country.module.scss';
import getDocTitle from '../../utils/getDocTitle';
import { Helmet } from 'react-helmet-async';

export function Country() {
  const [countryInfo, setCountryInfo] = useState<CountryInfoInterface>();
  const [featuredUnits, setFeaturedUnits] = useState<UnitInterface[]>([]);
  const [resaleUnits, setResaleUnits] = useState<UnitInterface[]>([]);

  const { countrySlug, countryId } = useParams<{
    countrySlug: string;
    countryId: string;
  }>();

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (countrySlug) {
      CountryUtilities.getCountryInfo(countrySlug)
        .then((res) => setCountryInfo(res))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [countrySlug]);

  useEffect(() => {
    if (countryInfo?.show_resale && countrySlug) {
      const params: Record<string, string> = {};

      params[`${FiltersEnums.COUNTRIES}[0]`] = countrySlug;
      params['on_resale'] = '1';

      const searchParams = new URLSearchParams(params);

      UnitUtilities.getUnits(1, 10, searchParams)
        .then((res) => setResaleUnits(res.data))
        .catch((err) => console.log(err));
    }
    if (
      countrySlug &&
      countryInfo?.section &&
      !isEmpty(countryInfo.section.tags)
    ) {
      const params: Record<string, string> = {};

      params['countries[0]'] = countrySlug;

      countryInfo.section.tags.forEach((tag, index) => {
        params[`${FiltersEnums.TAGS}[${index}]`] = tag.id.toString();
      });

      const searchParams = new URLSearchParams(params);

      UnitUtilities.getUnits(1, 10, searchParams)
        .then((res) => setFeaturedUnits(res.data))
        .catch((err) => console.log(err));
    }
  }, [countrySlug, countryInfo]);

  if (!countryInfo) {
    return <Loader />;
  }
  const countryDocTitle = getDocTitle(countryInfo.name);
  return (
    <div>
      <Helmet>
        <title>{countryDocTitle}</title>
        <meta property="og:title" content={countryDocTitle} />
        <meta name="description" content={countryInfo.about_description} />
        <meta
          property="og:description"
          content={countryInfo.about_description}
        />
      </Helmet>
      <CompareButton />
      <div>
        <div className={styles['banner']}>
          <ImageTextOverlay
            image={countryInfo.welcome_image}
            title={t('country')}
            subTitle={countryInfo.name}
            gradient
          />
        </div>
        <div className={`${styles['intro']} container`}>
          <p className={styles['description']}>
            {countryInfo.welcome_description}
          </p>
          <ExploreIndicator title={countryInfo.scroll_title} />
        </div>
      </div>

      <AboutOrascom
        title={t('ourInfluence')}
        subTitle={countryInfo.about_title}
        description={countryInfo.about_description}
        aboutInfo={countryInfo.metadata?.about ?? []}
        aboutImage={countryInfo.about_image}
        showAboutButton={false}
      />
      <CountryDestinations />
      {featuredUnits?.length || resaleUnits?.length ? (
        <div className={styles['explore-units__title']}>
          <h6 className="orascom__title">{t('browseProperties')}</h6>
          <h3 className="orascom__sub-title">
            {t('exploreUnitsIn')} {countryInfo.name}
          </h3>
        </div>
      ) : null}
      {featuredUnits?.length > 0 && (
        <PropertyCardsSwiper
          title={t('featuredProperties')}
          units={featuredUnits}
        />
      )}
      {resaleUnits?.length > 0 && (
        <PropertyCardsSwiper
          title={t('propertiesForResale')}
          units={resaleUnits}
        />
      )}
      <ReachUs
        description={t('weWouldLoveToShareIdeas')}
        primaryButton={
          <GoldButton onClick={handleShowModal}>{t('getInTouch')}</GoldButton>
        }
      />

      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={t('talkToSales')}
      >
        <ContactUsForm
          title={t('leaveYourContactInfo')}
          onCloseModal={handleCloseModal}
          country={countryInfo.id}
          countryName={countryInfo.name}
          countryPage
        />
      </Modal>
    </div>
  );
}

export default Country;
