/* eslint-disable @nx/enforce-module-boundaries */
import styles from './unit-services.module.scss';
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'libs/common-components/src/lib/modal/modal';
import { Loader } from '@orascom/common-components';
import { UnitDetails } from '@orascom/api-interfaces';
import HomeIcon from '../../../assets/icons/home.svg?react';
import SiteVisitIcon from '../../../assets/icons/request-visit.svg?react';
import ChangeOwnerIcon from '../../../assets/icons/transfer-user.svg?react';
import CashIcon from '../../../assets/icons/cash.svg?react';
import HandoverIcon from '../../../assets/icons/request-chat.svg?react';
import ChangeContactIcon from '../../../assets/icons/user.svg?react';
import RequestServiceForm from 'apps/orascom-shopper-app/src/components/pre-delivery/request-service-form/request-service-form';
import ServicesRequests from 'apps/orascom-shopper-app/src/components/pre-delivery/serivices-requests/serivices-requests';
import ChangeContactDetailsForm from 'apps/orascom-shopper-app/src/components/pre-delivery/change-contact-details-form/change-contact-details-form';
import { ServiceRequestTypeEnum } from 'apps/orascom-shopper-app/src/definitions/interfaces/service-requests.interface';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';
import { getCustomerId } from 'apps/orascom-shopper-app/src/utils/common.utils';
import { useGetMyUnits } from '../hooks/use-get-my-units';
import { UserReservationDetailsInterface } from 'apps/orascom-shopper-app/src/definitions/interfaces/common.interface';
import unitImagePlaceHolder from '../../../assets/images/uni-details.webp';

export function UnitServices() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [unitId, setUnitId] = useState<number>();
  const [customerId, setCustomerId] = useState<string>('');
  const [selectedUnit, setSelectedUnit] = useState<UnitDetails>();
  const [serviceType, setServiceType] = useState<ServiceRequestTypeEnum>(
    ServiceRequestTypeEnum.UNIT_MODIFICATION
  );
  const [serviceTypeTitle, setServiceTypeTitle] = useState<string>('');

  const [activeTab, setActiveTab] = useState<'available' | 'requested'>(
    'available'
  );

  const [myUnits, loadingMyUnits] = useGetMyUnits();

  const userContext = useContext(USER_CONTEXT);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  const handleShowContactModal = () => {
    setShowContactModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseContactModal = () => {
    setShowContactModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (myUnits.length) {
      setUnitId(myUnits[0].id);
      setSelectedUnit(myUnits[0]);
    }
  }, [myUnits]);

  useEffect(() => {
    if (unitId) {
      const foundCustomerId = getCustomerId(
        unitId,
        userContext.userReservationDetails as UserReservationDetailsInterface
      );
      if (foundCustomerId) {
        setCustomerId(foundCustomerId);
      }
    }
  }, [unitId]);

  if (loadingMyUnits) {
    return <Loader />;
  }

  return (
    <div className={`${styles['unit-services']} pre-delivery-container`}>
      <h1 className="orascom__sub-title">{t('unitServices')}</h1>
      <div className={styles['wrapper']}>
        <div>
          {myUnits.length > 0 ? (
            <>
              <legend>{t('selectYourUnit')}</legend>

              <div role="group" className={styles['units']}>
                {myUnits.map((unit, index) => (
                  <label key={unit.id} className={styles['unit-wrapper']}>
                    <div className={styles['unit']}>
                      <img
                        src={unit.cover_image ?? unitImagePlaceHolder}
                        alt=""
                        loading="lazy"
                      />
                      <div className={styles['unit-info']}>
                        <div>
                          <p>
                            {`${unit.project.destination.name} - ${unit.project.name}`}
                          </p>
                          <p className={styles['unit-name']}>
                            {unit.unit_type.name}
                          </p>
                        </div>
                        <input
                          name="unitId"
                          type="radio"
                          defaultChecked={index == 0}
                          value={unit.id}
                          onChange={() => {
                            setSelectedUnit(unit);
                            setUnitId(unit.id);
                          }}
                        ></input>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
              <div role="group" className={styles['services']}>
                <legend>{t('services')}</legend>
                <div className={styles['filter']}>
                  <button
                    onClick={() => setActiveTab('available')}
                    className={`${styles['tab']} ${
                      activeTab === 'available' && styles['active-filter']
                    }`}
                  >
                    {t('available')}
                  </button>
                  <button
                    onClick={() => setActiveTab('requested')}
                    className={`${styles['tab']} ${
                      activeTab === 'requested' && styles['active-filter']
                    }`}
                  >
                    {t('requested')}
                  </button>
                </div>
              </div>

              {activeTab === 'available' && (
                <div className={styles['available-services']}>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(ServiceRequestTypeEnum.UNIT_MODIFICATION);
                      setServiceTypeTitle(t('modificationRequest'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <HomeIcon />
                      {t('modificationRequest')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(ServiceRequestTypeEnum.SITE_VISITS);
                      setServiceTypeTitle(t('siteVisit'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <SiteVisitIcon />
                      {t('siteVisit')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(
                        ServiceRequestTypeEnum.CHANGE_OF_OWNERSHIP
                      );
                      setServiceTypeTitle(t('changeOfOwnership'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <ChangeOwnerIcon />
                      {t('changeOfOwnership')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(
                        ServiceRequestTypeEnum.FINANCIAL_CLEARANCE
                      );
                      setServiceTypeTitle(t('financialClearance'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <CashIcon />
                      {t('financialClearance')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(
                        ServiceRequestTypeEnum.CLIENT_RELATIONS_HANDOVER
                      );
                      setServiceTypeTitle(t('handoverRequest'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <HandoverIcon />
                      {t('handoverRequest')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => handleShowContactModal()}
                  >
                    <div>
                      <ChangeContactIcon />
                      {t('changeContactData')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(ServiceRequestTypeEnum.COMPLAINTS);
                      setServiceTypeTitle(t('complaints'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <HandoverIcon />
                      {t('complaints')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(
                        ServiceRequestTypeEnum.CANCELLATION_REQUEST
                      );
                      setServiceTypeTitle(t('cancellation'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <HandoverIcon />
                      {t('cancellation')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(ServiceRequestTypeEnum.FINANCIAL_REQUEST);
                      setServiceTypeTitle(t('financeRequests'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <CashIcon />
                      {t('financeRequests')}
                    </div>
                  </button>
                  <button
                    className={styles['available-services-button']}
                    type="button"
                    onClick={() => {
                      setServiceType(ServiceRequestTypeEnum.BOUNCED_PAYMENT);
                      setServiceTypeTitle(t('bouncedPayments'));
                      handleShowModal();
                    }}
                  >
                    <div>
                      <CashIcon />
                      {t('bouncedPayments')}
                    </div>
                  </button>
                  <Modal
                    show={showModal}
                    onClose={handleCloseModal}
                    title={serviceTypeTitle}
                  >
                    <RequestServiceForm
                      myUnits={myUnits}
                      unitId={Number(unitId)}
                      serviceType={serviceType}
                      customerId={customerId}
                      onClose={handleCloseModal}
                    />
                  </Modal>
                  <Modal
                    show={showContactModal}
                    onClose={handleCloseContactModal}
                    title={t('changeContactDetailsRequest')}
                  >
                    <ChangeContactDetailsForm
                      unitId={Number(unitId)}
                      customerId={customerId}
                      serviceType={serviceType}
                      onClose={handleCloseContactModal}
                    />
                  </Modal>
                </div>
              )}
              {activeTab === 'requested' && (
                <ServicesRequests
                  selectedUnit={selectedUnit}
                  customerId={customerId}
                />
              )}
            </>
          ) : (
            <p>no unit services found</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnitServices;
