import { Route } from '@orascom/api-interfaces';
import Home from '../pages/home/home';
import Country from '../pages/country/country';
import Destination from '../pages/destination/destination';
import AvailableUnits from '../pages/available-units/available-units';
import UnitDetails from '../pages/unit-details/unit-details';
import ContactUs from '../pages/contact-us/contact-us';
import CompareProperties from '../pages/compare-properties/compare-properties';

import Referrals from '../pages/pre-delivery/referrals/referrals';
import Unit from '../pages/pre-delivery/unit/unit';
import OwnerCards from '../pages/pre-delivery/owner-cards/owner-cards';
import MyUnits from '../pages/pre-delivery/my-units/my-units';
import GetHelp from '../pages/pre-delivery/get-help/get-help';
import AboutUs from '../pages/about-us/about-us';
import Offers from '../pages/pre-delivery/offers/offers';
import NewsAndEvents from '../pages/news-and-events/news-and-events';
import NewsAndEventsDetails from '../pages/news-and-events-details/news-and-events-details';
import UnitPayments from '../pages/pre-delivery/unit-payments/unit-payments';
import UnitPayment from '../pages/pre-delivery/unit-payment/unit-payment';
import Investment from '../pages/investment/investment';
import TermsAndConditions from '../pages/terms-and-conditions/terms-and-conditions';
import PrivacyPolicy from '../pages/privacy-policy/privacy-policy';
import FAQs from '../pages/faqs/faqs';
import WishList from '../pages/wish-list/wish-list';
import MyReferrals from '../pages/pre-delivery/my-referrals/my-referrals';
import UnitServices from '../pages/pre-delivery/unit-services/unit-services';
import ReserveProperty from '../pages/reserve-property/reserve-property';
import SignUp from '../pages/sign-up/sign-up';
import Login from '../pages/login/login';
import PreDeliveryNewsAndEvent from '../pages/pre-delivery/news-and-events/news-and-events';
import UnitAddons from '../pages/pre-delivery/unit-addons/unit-addons';
import UnitAddon from '../pages/pre-delivery/unit-addon/unit-addon';
import BuyAddon from '../pages/pre-delivery/buy-addon/buy-addon';
import ResellAssistance from '../pages/pre-delivery/resell-assistance/resell-assistance';
import PredeliveryAccountSettings from '../pages/pre-delivery/account-settings/account-settings';
import BookingExperience from '../pages/booking-experience/booking-experience';
import SignUnitContract from '../pages/pre-delivery/sign-contract/sign-contract';
import NotFoundPage from '../pages/not-found/not-found';
import ShopperAccountSettings from '../pages/account-settings/account-settings';
import NotAvailableUnitPage from '../pages/not-available-unit/not-available-unit-page';
import InteractiveMasterplan from '../pages/interactive-masterplan/interactive-masterplan';
import Highlights from '../pages/highlights/highlights';

export const ROUTES: Record<string, Route> = {
  Login: {
    path: '/login',
    component: Login,
    public: true,
    layout: 'auth',
  },

  Register: {
    path: '/register',
    component: SignUp,
    public: true,
    layout: 'auth',
  },

  Home: {
    path: '/',
    component: Home,
    public: true,
    layout: 'home',
  },

  Country: {
    path: '/country/:countrySlug/:countryId',
    getPath: (countrySlug: string, countryId: string) =>
      `/country/${countrySlug}/${countryId}`,
    component: Country,
    public: true,
    layout: 'default',
  },

  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: true,
    layout: 'default',
  },

  AvailableUnits: {
    path: '/available-units',
    component: AvailableUnits,
    public: true,
    layout: 'default',
  },

  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: number) => `/unit-details/${unitId}`,
    component: UnitDetails,
    public: true,
    layout: 'default',
  },

  ReserverProperty: {
    path: '/reserve-unit/:unitId',
    getPath: (unitId: string) => `/reserve-unit/${unitId}`,
    component: ReserveProperty,
    public: true,
    layout: 'reserve-property',
  },

  FAQ: {
    path: '/faqs',
    component: FAQs,
    public: true,
    layout: 'default',
  },

  ContactUs: {
    path: '/contact-us',
    component: ContactUs,
    public: true,
    layout: 'default',
  },

  CompareProperties: {
    path: '/compare-properties',
    component: CompareProperties,
    public: true,
    layout: 'default',
  },

  Investment: {
    path: '/investment',
    component: Investment,
    public: true,
    layout: 'default',
  },

  NewsEvents: {
    path: '/news-events',
    component: NewsAndEvents,
    public: true,
    layout: 'default',
  },

  InnerNewsEvents: {
    path: '/news-events/:newsSlug',
    getPath: (newsSlug: string) => `/news-events/${newsSlug}`,
    component: NewsAndEventsDetails,
    public: true,
    layout: 'default',
  },

  AboutUs: {
    path: '/about-us',
    component: AboutUs,
    public: true,
    layout: 'default',
  },
  PrivacyPolicy: {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    public: true,
    layout: 'default',
  },
  TermsAndConditions: {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    public: true,
    layout: 'default',
  },
  WishList: {
    path: '/wishlist',
    component: WishList,
    public: true,
    layout: 'default',
  },
  AccountSettings: {
    path: '/account-settings',
    component: ShopperAccountSettings,
    public: true,
    layout: 'default',
  },
  BookingExperience: {
    path: '/booking-experience',
    component: BookingExperience,
    public: true,
    layout: 'default',
  },
  Highlights: {
    path: '/highlights/:pageSlug',
    getPath: (pageSlug: string) => `/highlights/${pageSlug}`,
    component: Highlights,
    public: true,
    layout: 'default',
  },
  NotAvailableUnit: {
    path: '/not-available-unit',
    component: NotAvailableUnitPage,
    public: true,
    layout: 'default',
  },
  InteractiveMasterplan: {
    path: '/interactive-masterplan',
    component: InteractiveMasterplan,
    public: true,
    layout: 'default',
  },
  /**
   * Always keep this route at the end of the routes object.
   */
  NotFound: {
    path: '*',
    component: NotFoundPage,
    public: true,
    layout: 'auth',
  },

  Pre_Delivery_Home: {
    path: '/pre-delivery/units',
    component: MyUnits,
    public: true,
    app: 'pre-delivery',
  },

  Pre_Delivery_Referrals: {
    path: '/pre-delivery/referrals',
    component: Referrals,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_My_Referrals: {
    path: '/pre-delivery/my-referrals',
    component: MyReferrals,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Unit: {
    path: '/pre-delivery/units/:unitId',
    getPath: (unitId: string) => `/pre-delivery/units/${unitId}`,
    component: Unit,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Sign_Contract: {
    path: '/pre-delivery/sign-contract/:unitId',
    getPath: (unitId: string) => `/pre-delivery/sign-contract/${unitId}`,
    component: SignUnitContract,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Payments: {
    path: '/pre-delivery/payments',
    component: UnitPayments,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Payment: {
    path: '/pre-delivery/payments/:unitId',
    getPath: (unitId: string) => `/pre-delivery/payments/${unitId}`,
    component: UnitPayment,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Services: {
    path: '/pre-delivery/services',
    component: UnitServices,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Addons: {
    path: '/pre-delivery/addons',
    component: UnitAddons,
    public: true,
    app: 'pre-delivery',
  },

  Pre_Delivery_Addon: {
    path: '/pre-delivery/addons/:addonId',
    getPath: (addonId: string) => `/pre-delivery/addons/${addonId}`,
    component: UnitAddon,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Buy_Addon: {
    path: '/pre-delivery/addons/purchase/:addonId',
    getPath: (addonId: string) => `/pre-delivery/addons/purchase/${addonId}`,
    component: BuyAddon,
    public: true,
    app: 'pre-delivery',
    side_nav: false,
  },
  Pre_Delivery_Resell: {
    path: '/pre-delivery/resell-assistance',
    component: ResellAssistance,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Offers: {
    path: '/pre-delivery/offers',
    component: Offers,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Settings: {
    path: '/pre-delivery/settings',
    component: PredeliveryAccountSettings,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Owner_Cards: {
    path: '/pre-delivery/owner-cards',
    component: OwnerCards,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_Get_Help: {
    path: '/pre-delivery/get-help',
    component: GetHelp,
    public: true,
    app: 'pre-delivery',
  },
  Pre_Delivery_News_And_Events: {
    path: '/pre-delivery/news',
    component: PreDeliveryNewsAndEvent,
    public: true,
    app: 'pre-delivery',
  },
};
