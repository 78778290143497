/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FadeInSection,
  ImageTextOverlay,
  Loader,
  Modal,
  CardsSlider,
} from '@orascom/common-components';
import BookingExperience from '../../components/booking-experience/booking-experience';
import ReachUs from '../../components/common/reach-us/reach-us';
import { GoldButton, NavyButton } from '../../components/common/button/button';
import aboutCover from '../../assets/images/about-cover.webp';
import WelcomeBanner from '../../components/welcome-banner/welcome-banner';
import { AboutOrascom } from '../../components/common/about-orascom/about-orascom';
import { Home as HomeUtilities } from '../../utils/home.utils';
import {
  PagesDataInterface,
  NewsListingInterface,
  ActivePromotionInterface,
} from '../../definitions/interfaces/home.interface';
import FindProperty from '../../components/find-property/find-property';
import NewsCard from '../../components/common/news-card/news-card';
import { Country as CountryUtilities } from '../../utils/country.utils';

import styles from './home.module.scss';
import { Country } from '../../definitions/interfaces/country.interface';
import DestinationsSlider from '../../components/destinations-slider/destinations-slider';
import { ROUTES } from '../../api/routes';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import PromotionalPopup from '../../components/promotional-popup/promotional-popup';
import usePromotion from '../../hooks/use-promotional-popup';

const homeDocTitle = getDocTitle();
export function Home() {
  const [homeMetaData, setHomeMetaData] = useState<PagesDataInterface>();
  const [latestNews, setLatestNews] = useState<NewsListingInterface[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const [activePromotion, setActivePromotion] =
    useState<ActivePromotionInterface>();

  const { handleClosePromotion, promotionalModal } = usePromotion(
    activePromotion?.id
  );

  const { t } = useTranslation();

  useEffect(() => {
    HomeUtilities.getHomeMetaData()
      .then((res) => {
        setHomeMetaData(res);
      })
      .catch((err) => {
        console.log(err);
      });

    HomeUtilities.getNews(1, 10)
      .then((res) => setLatestNews(res.data))
      .catch((err) => {
        console.log(err);
      });
    HomeUtilities.getActivePromotions()
      .then((res) => {
        setActivePromotion(
          res.find((promotion) => promotion.destination === null)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    CountryUtilities.getCountries()
      .then((res) => setCountries(res))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!homeMetaData) {
    return <Loader />;
  }

  return (
    <div>
      {/* <ResumeReservationBanner /> */}
      <Helmet>
        <title>{homeDocTitle}</title>
        <meta property="og:title" content={homeDocTitle} />
        <meta name="description" content={t('orascomDevelopmentStatement')} />
        <meta
          property="og:description"
          content={t('orascomDevelopmentStatement')}
        />
      </Helmet>
      <WelcomeBanner />
      <AboutOrascom
        title={t('qualityLife')}
        subTitle={t('createDestinationsBuildCommunities')}
        description={t('orascomDevelopmentStatement')}
        aboutInfo={homeMetaData.metadata.quality}
        showAboutButton={false}
        aboutPageButton={true}
        videoUrl={homeMetaData.video}
        playlistUrl={homeMetaData.video_playlist}
      />

      <FadeInSection>
        <div className={styles['about-wrapper']}>
          <ImageTextOverlay
            image={aboutCover}
            title={t('aboutOrascom')}
            subTitle={t('stepIntoOrascom')}
          />
        </div>

        <div className={styles['about']}>
          <h3>
            {t('weBuild')} <br /> {t('sufficientDestinations')}
          </h3>
          <p>{t('aboutOrascomBody')}</p>
        </div>
      </FadeInSection>

      {countries.length > 0 && <DestinationsSlider countries={countries} />}

      <FindProperty />
      <FadeInSection>
        <BookingExperience info={homeMetaData.metadata.general} />
      </FadeInSection>

      {latestNews.length > 0 && (
        <div className={`${styles['latest-news']} container`}>
          <p className="orascom__title">{t('latestNews')}</p>
          <p className="orascom__sub-title">{t('happeningInOrascom')}</p>
          <CardsSlider>
            {latestNews.map((item) => (
              <NewsCard
                key={uuidv4()}
                image={item.image}
                date={item.date}
                title={item.title}
                path={ROUTES.InnerNewsEvents.getPath?.(item.slug)}
              />
            ))}
          </CardsSlider>
          <NavyButton asLink to={ROUTES.NewsEvents.path}>
            {t('viewAllNews')}
          </NavyButton>
        </div>
      )}
      <ReachUs
        description={t('weWouldLoveToShareIdeas')}
        primaryButton={
          <GoldButton asLink to={ROUTES.ContactUs.path}>
            {t('getInTouch')}
          </GoldButton>
        }
      />
      {activePromotion && (
        <Modal
          show={promotionalModal}
          onClose={handleClosePromotion}
          customMaxWidth={665}
          hideHeaderBorder
          customBackground="-webkit-linear-gradient(85.33deg,#f5f7fa 0%,#c7cdd9 100.68%)"
          overlapHeader
        >
          <PromotionalPopup
            imgUrl={activePromotion.image}
            description={activePromotion.description}
            name={activePromotion.name}
          />
        </Modal>
      )}
    </div>
  );
}

export default Home;
