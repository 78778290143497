// eslint-disable-next-line @nx/enforce-module-boundaries
import Modal from 'libs/common-components/src/lib/modal/modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoldButton } from '../common/button/button';
import SearchBar from '../common/search-bar/search-bar';
import styles from './find-property.module.scss';

export function FindProperty() {
  const [showSearchModal, setShowSearchModal] = useState(false);

  const { t } = useTranslation();

  const handleShowSearchModal = () => {
    setShowSearchModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setShowSearchModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    setShowSearchModal(false);
  }, [location.pathname]);

  return (
    <div className={styles['find-property']}>
      <div className={styles['find-property__content']}>
        <h6 className={`${styles['find-property__title']} orascom__title`}>
          {t('whereMagicStarts')}
        </h6>
        <h3
          className={`${styles['find-property__sub-title']} orascom__sub-title`}
        >
          {t('findYourFutureProperty')}
        </h3>
        <GoldButton
          secondary
          className={styles['mobile-btn']}
          onClick={handleShowSearchModal}
        >
          Explore properties
        </GoldButton>
        <SearchBar />
      </div>
      <div className={styles['find-property__overlay']}></div>

      <Modal
        show={showSearchModal}
        onClose={handleCloseModal}
        title={t('search')}
        bodyBackgroundClass="search-modal__background"
      >
        <div className="search-modal">
          <SearchBar />
        </div>
      </Modal>
    </div>
  );
}

export default FindProperty;
