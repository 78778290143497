import styles from './country-office-card.module.scss';
import EmailIcon from '../../../assets/icons/email.svg';
import PhoneIcon from '../../../assets/icons/phone.svg';

/* eslint-disable-next-line */
export interface CountryOfficeCardProps {
  office: CountryOffice;
}

export interface CountryOffice {
  title: string;
  address: string;
  phone: string;
  email: string;
  icon: string;
}

export function CountryOfficeCard(props: Readonly<CountryOfficeCardProps>) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['wrapper__content']}>
        <p className={styles['name']}>{props.office.title}</p>
        <p className={styles['address']}>{props.office.address}</p>
      </div>

      <div className={styles['wrapper__content']}>
        <p className={styles['phone']}>
          <img src={PhoneIcon} role="presentation" alt="" />
          <span>{props.office.phone}</span>
        </p>
        <p className={styles['email']}>
          <img src={EmailIcon} role="presentation" alt="" />
          <span>{props.office.email}</span>
        </p>
      </div>
    </div>
  );
}

export default CountryOfficeCard;
