export const showPreDeliveryPay =
  process.env.NX_HIDE_PREDELIVERY_PAYMENTS !== 'true';

export const showPreDeliveryLangs =
  process.env.NX_HIDE_PREDELIVERY_LANGS !== 'true';

export const showDestBankTransfer =
  process.env.NX_HIDE_DEST_BANK_TRANSFER !== 'true';

export const showReservationButton =
  process.env.NX_HIDE_RESERVATION_FLOW_BUTTON !== 'true';

export const allowMultipleReservations =
  process.env.NX_ALLOW_MULTIPLE_RESERVATIONS === 'true';

export const showOnlinePayment = (countrySlug: string) =>
  process.env[`NX_HIDE_${countrySlug.toUpperCase()}_ONLINE_PAYMENT`] !== 'true';
