import styles from './sign-up.module.scss';

import AuthImage from '../../assets/images/auth.webp';
import Header from '../../components/header/header';
import MinimalFooter from '../../components/common/minimal-footer/minimal-footer';
import SignUpForm from '../../components/sign-up-form/sign-up-form';
import PhoneVerification from '../../components/phone-verification/phone-verification';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SignUpFormInterface } from '../../definitions/interfaces/authentication.interface';
import { Authentication as AuthenticationUtils } from '../../utils/authentication.utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { getParamFromSearchUrl } from '@orascom/utils';
import { isEmpty } from '../../utils/common.utils';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const signUpDocTitle = getDocTitle('Sign Up');
export function SignUp() {
  const { t } = useTranslation();
  const [showVerification, setShowVerification] = useState(false);
  const [formValues, setFormValues] = useState<Required<SignUpFormInterface>>();
  const handleOnFinish = (values: Required<SignUpFormInterface>) => {
    setFormValues(values);
    setShowVerification(true);
  };
  const UserContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (UserContext?.user) {
      navigate(ROUTES.Home.path, { replace: true });
    }
  }, []);

  const onSubmitOTP = (OTP: string): Promise<unknown> => {
    if (!formValues) {
      // TODO: Handle missing form values Error!
      return Promise.reject();
    }

    const phoneNumberWithCountryCode = `+${formValues.code}${formValues.phone}`;

    return AuthenticationUtils.registerUserOnBackend(
      OTP,
      formValues.email,
      formValues.name,
      phoneNumberWithCountryCode
    )
      .then((res) => {
        if (UserContext.setUser) {
          UserContext?.setUser(res.user);
          const redirect = getParamFromSearchUrl('redirect', location.search);
          if (!isEmpty(redirect)) {
            setTimeout(() => {
              navigate(redirect[0]);
            }, 4000);
          } else {
            setTimeout(() => {
              navigate(ROUTES.Home.path, { replace: true });
            }, 4000);
          }
        } else {
          console.error('Unexpected Error, No User Context!!!');
        }
      })
      .catch((error: Error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  const onResendCode = () => {
    if (!formValues?.code || !formValues?.phone) {
      // TODO: Handle no form values;
      console.warn('[onResendCode] Errors no form values');
    }

    const phoneNumberWithCountryCode = `+${formValues?.code}${formValues?.phone}`;
    AuthenticationUtils.resendOTP(phoneNumberWithCountryCode)
      .then(() => toast.success(t('resendOtpSuccessMsg') as string))
      .catch((errors) => {
        // TODO: Handle unable to resend OTP
        console.warn('[onResendCode] Errors', errors);
      });
  };

  return (
    <div className={`${styles['container']} auth-container`}>
      <Helmet>
        <title>{signUpDocTitle}</title>
        <meta property="og:title" content={signUpDocTitle} />
        <meta name="description" content={t('signUpMetaDescription')} />
        <meta property="og:description" content={t('signUpMetaDescription')} />
      </Helmet>
      <div className={styles['wrapper']}>
        <Header header_transparent={false} auth header_home={false} />
        <img
          src={AuthImage}
          role="presentation"
          alt=""
          className={styles['mobile-image']}
          loading="lazy"
        />
        {showVerification ? (
          <PhoneVerification
            values={formValues}
            onChangeNumber={() => setShowVerification(false)}
            onSubmit={onSubmitOTP}
            onResendCode={onResendCode}
          />
        ) : (
          <SignUpForm onFinish={handleOnFinish} values={formValues} />
        )}
        <div id="recaptcha-verifier" />
        <MinimalFooter />
      </div>

      <img
        src={AuthImage}
        role="presentation"
        alt=""
        className={styles['image']}
        loading="lazy"
      />
    </div>
  );
}

export default SignUp;
