import Numeral from 'numeral';
import { UnitInterface } from '@orascom/api-interfaces';
import { useContext, useEffect, useState } from 'react';
import { UNIT_COMPARE_CONTEXT } from '../../contexts/unit-comparison.context';
import {
  analyticsAddToCompareCustomEvent,
  analyticsAddToFavoritesCustomEvent,
} from '../../utils/analytics-events';
import { useTranslation } from 'react-i18next';
import styles from './similar-units.module.scss';
import areaIcon from '../../assets/icons/area.svg';
import bedroomIcon from '../../assets/icons/bedroom.svg';
import bathroomIcon from '../../assets/icons/bathroom.svg';
import HeartFilledIcon from '../../assets/icons/heart-filled.svg';
import LoveIcon from '../../assets/icons/love.svg';
import {
  handleAddToWishList,
  handleRemoveFromWishList,
} from '../../utils/wishlist.utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { CommonEventParameters, CurrencyContext } from '@orascom/utils';

export interface SimilarUnitsProps {
  unit: UnitInterface;
}
export function SimilarUnitCard(props: Readonly<SimilarUnitsProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [inComparison, setInComparison] = useState(false);
  const [wishListed, setWishListed] = useState(false);

  const unitContext = useContext(UNIT_COMPARE_CONTEXT);
  const userContext = useContext(USER_CONTEXT);
  const { rates, currency } = useContext(CurrencyContext);

  useEffect(() => {
    if (unitContext.units) {
      setInComparison(unitContext.units?.includes(props.unit.id));
    }
  }, [unitContext, props.unit.id]);

  useEffect(() => {
    setWishListed(props.unit.is_user_saved);
  }, [props.unit]);

  let displayedUnitCurrency = props.unit.currency;
  let displayedUnitPrice = props.unit.price;

  if (rates && props.unit) {
    if (currency !== props.unit?.currency) {
      const rateKey = `${currency}${props.unit?.currency}`;
      displayedUnitCurrency = currency;
      displayedUnitPrice = props.unit.price / Number(rates[rateKey]);
    }
  }
  const analyticsEventParams: CommonEventParameters = {
    name: `added unitId: ${props.unit.id}`,
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
    country: props.unit.project.destination.country_name,
    destination: props.unit.project.destination.name,
  };
  const handleAddToCompare = () => {
    if (inComparison) {
      unitContext.removeUnit?.(props.unit.id);
    } else {
      analyticsAddToCompareCustomEvent(analyticsEventParams);
      unitContext.addUnit?.(props.unit.id);
    }
  };

  const handleEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!inComparison) {
      analyticsAddToCompareCustomEvent(analyticsEventParams);
    }
  };

  return (
    <div className="property-card">
      {wishListed ? (
        <button
          type="button"
          className="property-card__wishlist-button"
          onClick={() =>
            handleRemoveFromWishList(
              props.unit.id,
              setWishListed,
              t('unitRemovedFromWishlist')
            )
          }
        >
          <img src={HeartFilledIcon} alt="love" />
        </button>
      ) : (
        <button
          type="button"
          className="property-card__wishlist-button"
          onClick={(e) => {
            e.stopPropagation();
            if (!userContext.user?.id) {
              navigate(ROUTES.Login.path);
              console.log('clicked');
              return;
            }
            handleAddToWishList(
              props.unit.id,
              setWishListed,
              t('unitAddedToWishlist')
            );
            analyticsAddToFavoritesCustomEvent(analyticsEventParams);
          }}
        >
          <img src={LoveIcon} alt="love" />
        </button>
      )}
      {Boolean(props.unit.is_resale) && (
        <span className="property-card__tag">{t('resale')}</span>
      )}
      <Link
        className={styles['unit-container']}
        to={ROUTES.UnitDetails.getPath?.(props.unit.id)}
        key={props.unit.id}
      >
        <img
          className="property-card__image"
          src={props.unit.cover_image}
          alt={props.unit.project.name}
          role="presentation"
          loading="lazy"
        />
        <img
          src={props.unit.hover_image}
          alt={props.unit.project.name}
          className="property-card__image--hidden"
          loading="lazy"
        />
        <div className={styles['unit-details']}>
          <div>
            <p>
              {props.unit.project.destination.name} - {props.unit.project.name}
            </p>
            <p>
              <b>{props.unit.unit_type.name}</b>
            </p>
          </div>
          <p>
            <b>
              {Numeral(displayedUnitPrice).format('0,0')}{' '}
              {displayedUnitCurrency}
            </b>
          </p>
          <div className={styles['unit-info']}>
            {props.unit?.built_up_area ? (
              <div className={styles['detail-container']}>
                <img src={areaIcon} alt="" role="presentation" />
                {props.unit.built_up_area} {t('mSquared')}
              </div>
            ) : null}
            {props.unit?.bedrooms ? (
              <div className={styles['detail-container']}>
                <img src={bedroomIcon} alt="" role="presentation" />{' '}
                {props.unit.bedrooms} {t('bedrooms')}
              </div>
            ) : null}
            {props.unit?.bathrooms ? (
              <div className={styles['detail-container']}>
                <img src={bathroomIcon} alt="" role="presentation" />
                {props.unit.bathrooms} {t('bathrooms')}
              </div>
            ) : null}
          </div>
          <button
            className="property-card__compare"
            onClick={(e) => handleEvent(e)}
          >
            <input
              type="checkbox"
              name={`property-card__checkbox${props.unit.id}`}
              id={`property-card__checkbox${props.unit.id}`}
              checked={inComparison}
              readOnly
              onClick={() => handleAddToCompare()}
            />
            <label htmlFor={`property-card__checkbox${props.unit.id}`}>
              {inComparison ? t('addedToComparison') : t('addToCompare')}
            </label>
          </button>
        </div>
      </Link>
    </div>
  );
}
export default SimilarUnitCard;
