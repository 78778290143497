import { ReactNode, useEffect, useState } from 'react';
import plusSign from '../../../assets/icons/plus.svg';
import minusSign from '../../../assets/icons/minus.svg';
import arrowUpSign from '../../../assets/icons/arrow-up.svg';
import arrowDownSign from '../../../assets/icons/down-arrow.svg';
import downloadIcon from '../../../assets/icons/download-2.svg';
import styles from './accordion.module.scss';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nx/enforce-module-boundaries
import GenericPdfDownloader from 'libs/common-components/src/lib/pdf-downloader/pdf-downloader';

export interface AccordionProps {
  title: string | ReactNode;
  children: ReactNode;
  arrows?: boolean;
  alwaysOpen?: boolean;
  downloadUrl?: boolean;
  initialOpenState?: boolean;
  analyticsFilter?: boolean;
}

export function Accordion(props: Readonly<AccordionProps>) {
  const [isOpen, setIsOpen] = useState(
    props.initialOpenState ?? props.alwaysOpen
  );
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(props.initialOpenState ?? props.alwaysOpen);
  }, [props.alwaysOpen, props.initialOpenState]);

  const handleToggle = () => {
    if (!props.alwaysOpen && !props.analyticsFilter) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className={`${styles['wrapper']} accordion`}>
      <button
        className={`accordion__title ${isOpen ? 'accordion__open' : ''}`}
        onClick={handleToggle}
      >
        <div className="accordion__title-wrapper">
          {props.title}
          {props.arrows && !props.alwaysOpen && (
            <img
              src={isOpen ? arrowUpSign : arrowDownSign}
              alt=""
              role="presentation"
              className="accordion__icons"
            />
          )}
          {!props.arrows && !props.alwaysOpen && (
            <button
              className="accordion__title-wrapper-button"
              onClick={() =>
                !props.alwaysOpen && props.analyticsFilter && setIsOpen(!isOpen)
              }
            >
              <img
                onClick={() =>
                  !props.alwaysOpen &&
                  props.analyticsFilter &&
                  setIsOpen(!isOpen)
                }
                src={isOpen ? minusSign : plusSign}
                alt=""
                role="presentation"
                className="accordion__icons"
              />
            </button>
          )}
        </div>
        {props.downloadUrl && (
          <div className={styles['download']}>
            <GenericPdfDownloader
              downloadFileName="payment-plan-pdf"
              horizontalMargin={0}
              rootElementId="payment-plan"
              buttonText={t('downloadPDF')}
              className={styles['download-button']}
              icon={downloadIcon}
            />
          </div>
        )}
        {isOpen && <div className="accordion__title-background" />}
      </button>
      <div
        className={`accordion__item ${!isOpen ? 'accordion__collapsed' : ''}`}
      >
        <div className="accordion__content">{props.children}</div>
      </div>
    </div>
  );
}

export default Accordion;
