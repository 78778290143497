import { OptionValue } from '@orascom/api-interfaces';

export enum PortalPageEnum {
  UNIT_PAGE = '1',
  DESTINATION_PAGE = '2',
  COMPARE_FILLED_PAGE = '3',
  TALK_TO_SALES = '4',
  REFERRAL_PRE_DELIVERY = 5,
}

export enum ContactTypeEnum {
  VIRTUAL_MEETING = '1',
  MOBILE = '2',
  EMAIL = '3',
  WHATS_APP = '4',
}

export enum ContactTimeEnum {
  MORNING = '1',
  AFTERNOON = '2',
  NIGHT = '3',
}

export enum CustomerInquiryEnum {
  INVESTMENT = 1,
  UNIT_PURCHASING = 2,
  GENERAL = 3,
}

export interface FormInitialData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumbers: { countryCode: string; phone: string }[];
  country?: OptionValue | null;
  destination?: OptionValue | null;
  contactType?: OptionValue | null;
  contactTime?: OptionValue | null;
  wayToShareUnit?: OptionValue | null;
  message: string;
  requestFor?: OptionValue | null;
  shareUnit?: OptionValue | null;
  unitId?: OptionValue | null;
  referredDestination?: string;
}
export interface ContactUsFormData {
  name: string;
  phone: string;
  phone_2?: string;
  phone_3?: string;
  email: string;
  country_ids: number[];
  unit_id: number | null;
  destination_ids: number[];
  communication_channel: string | null;
  contact_time: string | null;
  message: string;
  subscribe_offers_and_news: 0 | 1;
  request_for: string | null;
  share_unit: string | null;
  referee_contact_channel: string | null;
}

export enum ReferralSharingWayStatus {
  RESERVATION_LINK = 1,
  CONTACTING_WITH_SALES = 2,
}
export interface SubmitLeadData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country: number;
  portal_page?: PortalPageEnum | null;
  customer_inquiry: number;
  destination_slug: string;
  is_predelivery: boolean;
  preferred_communication_method: string;
  preferred_communication_time: string;
  interested_in_unit_id?: string;
  portal_comments: string;
  referral_unit_name?: string;
  referral_customer?: string;
  referral_unit_destination?: string;
  referral_customer_name?: string;
  referral_way_to_share_unit?: string | number;
}
