/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Numeral from 'numeral';
import { ROUTES } from '../../../api/routes';
import { UnitInterface } from '@orascom/api-interfaces';
import bedroomIcon from '../../../assets/icons/bedroom.svg';
import bathroomIcon from '../../../assets/icons/bathroom.svg';
import areaIcon from '../../../assets/icons/area.svg';
import { UNIT_COMPARE_CONTEXT } from '../../../contexts/unit-comparison.context';
import './property-card.scss';
import HeartFilledIcon from '../../../assets/icons/heart-filled.svg';
import LoveIcon from '../../../assets/icons/love.svg';

import {
  handleAddToWishList,
  handleRemoveFromWishList,
} from 'apps/orascom-shopper-app/src/utils/wishlist.utils';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';
import {
  analyticsAddToCompareCustomEvent,
  analyticsAddToFavoritesCustomEvent,
} from 'apps/orascom-shopper-app/src/utils/analytics-events';
import useCurrencyConverter from 'apps/orascom-shopper-app/src/hooks/use-currency-converter';
import { CommonEventParameters } from '@orascom/utils';

export interface PropertyCardProps {
  unit: UnitInterface;
  wishlistPage?: boolean;
  removeFromWishList?: () => void;
}

export function PropertyCard(props: Readonly<PropertyCardProps>) {
  const [inComparison, setInComparison] = useState(false);
  const [wishListed, setWishListed] = useState(false);

  const { unit } = props;
  const { t } = useTranslation();

  // get price in selected currency
  const { convertToSelectedCurrency, selectedCurrency } =
    useCurrencyConverter();
  const { price } = convertToSelectedCurrency(unit.price, unit.currency);

  const unitContext = useContext(UNIT_COMPARE_CONTEXT);
  const userContext = useContext(USER_CONTEXT);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isUnitInComparison = unitContext.units?.find((u) => u === unit.id);
    if (isUnitInComparison) {
      setInComparison(true);
    } else {
      setInComparison(false);
    }
  }, [unitContext?.units]);

  useEffect(() => {
    setWishListed(unit.is_user_saved);
  }, [unit]);

  const analyticsEventParams: CommonEventParameters = {
    name: `added unitId: ${unit.id}`,
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
    country: unit.project.destination.country_name,
    destination: unit.project.destination.name,
  };

  const handleEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!inComparison) {
      analyticsAddToCompareCustomEvent(analyticsEventParams);
    }
  };

  return (
    <div className="property-card">
      {wishListed ? (
        <button
          type="button"
          className="property-card__wishlist-button"
          onClick={() =>
            handleRemoveFromWishList(
              unit.id,
              setWishListed,
              t('unitRemovedFromWishlist')
            )
          }
        >
          <img src={HeartFilledIcon} alt="love" />
        </button>
      ) : (
        <button
          type="button"
          className="property-card__wishlist-button"
          onClick={() => {
            if (!userContext.user?.id) {
              navigate(ROUTES.Login.path);
              return;
            }
            handleAddToWishList(
              unit.id,
              setWishListed,
              t('unitAddedToWishlist')
            );
            analyticsAddToFavoritesCustomEvent(analyticsEventParams);
          }}
        >
          <img src={LoveIcon} alt="love" />
        </button>
      )}
      <Link
        className="property-card__link"
        to={ROUTES.UnitDetails.getPath?.(unit.id)}
      >
        {props.wishlistPage && (
          <button
            type="button"
            className="property-card__wishlist-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.removeFromWishList?.();
            }}
          >
            <img src={HeartFilledIcon} role="presentation" alt="" />
          </button>
        )}

        {Boolean(unit.is_resale) && (
          <span className="property-card__tag">{t('resale')}</span>
        )}
        <img
          src={unit.cover_image}
          alt={unit.project.name}
          className="property-card__image"
          loading="lazy"
        />
        <img
          src={unit.hover_image}
          alt={unit.project.name}
          className="property-card__image--hidden"
          loading="lazy"
        />
        <div className="property-card__content">
          <h6 className="property-card__title">
            {`${unit.project.destination.name} - ${unit.project.name}`}
          </h6>
          <h6 className="property-card__sub-title">{`${unit.unit_type?.name} - (${unit.name})`}</h6>
          <h5 className="property-card__price">
            {Numeral(price).format('0,0')} {selectedCurrency}
          </h5>
          <div className="property-card__info">
            <div>
              <img src={bedroomIcon} alt="" role="presentation" />
              <span>
                {unit.bedrooms} {t('bedrooms')}
              </span>
            </div>
            <div>
              <img src={bathroomIcon} alt="" role="presentation" />
              <span>
                {unit.bathrooms} {t('bathrooms')}
              </span>
            </div>
            <div>
              <img src={areaIcon} alt="" role="presentation" />
              <span>
                {unit.built_up_area}
                {t('m²')}
              </span>
            </div>
          </div>
          <button
            className="property-card__compare"
            onClick={(e) => handleEvent(e)}
          >
            <input
              type="checkbox"
              name={`property-card__checkbox${unit.id}`}
              id={`property-card__checkbox${unit.id}`}
              checked={inComparison}
              readOnly
              onClick={() => {
                return inComparison
                  ? unitContext.removeUnit?.(unit.id)
                  : unitContext.addUnit?.(unit.id);
              }}
            />
            <label htmlFor={`property-card__checkbox${unit.id}`}>
              {inComparison ? t('addedToComparison') : t('addToCompare')}
            </label>
          </button>
        </div>
      </Link>
    </div>
  );
}

export default PropertyCard;
