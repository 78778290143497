/* eslint-disable @nx/enforce-module-boundaries */
import styles from './request-service-form.module.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  GoldButton,
  SelectDropdown,
  Spinner,
} from '@orascom/common-components';
import { UnitDetails, OptionValue } from '@orascom/api-interfaces';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../../assets/icons/error.svg';
import { useState } from 'react';
import {
  ServiceRequestFormData,
  ServiceRequestTypeEnum,
  // eslint-disable-next-line import/namespace
} from '../../../../src/definitions/interfaces/service-requests.interface';
import {
  ContactTimeEnum,
  ContactTypeEnum,
} from '../../../../src/definitions/interfaces/contact.interface';

/* eslint-disable-next-line */
export interface RequestServiceFormProps {
  myUnits: UnitDetails[];
  unitId: number;
  serviceType: ServiceRequestTypeEnum;
  customerId: string;
  onClose: () => void;
}
interface FormInitialData {
  contactType: OptionValue | null;
  contactTime: OptionValue | null;
  message: string;
}

export function RequestServiceForm(props: Readonly<RequestServiceFormProps>) {
  const { t } = useTranslation();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleSubmitForm = (
    formData: FormInitialData,
    resetForm: () => void
  ) => {
    if (formData.contactType && formData.contactTime) {
      const mappedData: ServiceRequestFormData = {
        customer_source_id: props.customerId,
        preferred_way_of_contact: Number(formData.contactType.value),
        preferred_time_of_contact: Number(formData.contactTime.value),
        request_details: formData.message,
        service_slug: props.serviceType,
      };

      if (props.unitId) {
        PreDeliveryUserUtilities.submitServiceRequest(
          props.unitId.toString(),
          mappedData
        )
          .then(() => {
            setSuccessMsg(true);
          })
          .catch(() => setErrorMsg(true))
          .finally(() => resetForm());
      }
    }
  };
  const UnitServicesSchema = Yup.object().shape({
    contactType: Yup.object().nullable().required(t('fieldIsRequired')),
    contactTime: Yup.object().nullable().required(t('fieldIsRequired')),

    message: Yup.string().max(250, t('maximumCharacters250')),
  });

  if (errorMsg) {
    return (
      <div>
        <div className={styles['error']}>
          <p className={styles['error-title']}>
            <img src={errorSubmitIcon} role="presentation" alt="" />
            <span>{t('formSubmitErrorTitle')}</span>
          </p>
          <p className={styles['error-message']}>
            {t('formSubmitErrorMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setErrorMsg(false)}>
          {t('Try again')}
        </GoldButton>
      </div>
    );
  }
  if (successMsg) {
    return (
      <div>
        <div className={styles['success']}>
          <p className={styles['success-title']}>
            <img src={successIcon} role="presentation" alt="" />
            <span>{t('formSubmitSuccessTitle')}</span>
          </p>
          <p className={styles['success-message']}>
            {t('formSubmitSuccessMessage')}
          </p>
        </div>
        <GoldButton
          onClick={() => {
            props.onClose();
            setSuccessMsg(false);
          }}
        >
          {t('done')}
        </GoldButton>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        contactType: null,
        contactTime: null,

        message: '',
      }}
      validationSchema={UnitServicesSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmitForm(values, resetForm);
      }}
    >
      {({ errors, touched, setFieldValue, values, isSubmitting }) =>
        !isSubmitting ? (
          <Form>
            <div className="input-wrapper">
              <label htmlFor="contactType">{t('preferredWayOfContact')}*</label>
              <SelectDropdown
                name="contactType"
                className={`${styles['select']} ${
                  errors.contactType && touched.contactType ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactType', option)}
                options={[
                  { value: ContactTypeEnum.MOBILE, label: 'Phone call' },
                  { value: ContactTypeEnum.WHATS_APP, label: 'Whatsapp' },
                  { value: ContactTypeEnum.EMAIL, label: 'Email' },
                  {
                    value: ContactTypeEnum.VIRTUAL_MEETING,
                    label: 'virtual meeting',
                  },
                ]}
                selectedOption={values.contactType ?? null}
              />
              {errors.contactType && touched.contactType ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.contactType}
                </p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <label htmlFor="contactTime">
                {t('preferredTimeOfContact')}*
              </label>
              <SelectDropdown
                name="contactTime"
                className={`${styles['select']} ${
                  errors.contactTime && touched.contactTime ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactTime', option)}
                options={[
                  { value: ContactTimeEnum.MORNING, label: 'Morning' },
                  { value: ContactTimeEnum.AFTERNOON, label: 'Afternoon' },
                  { value: ContactTimeEnum.NIGHT, label: 'Night' },
                ]}
                selectedOption={values.contactTime ?? null}
              />
              {errors.contactTime && touched.contactTime ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.contactTime}
                </p>
              ) : null}
            </div>

            <div className="input-wrapper">
              <label htmlFor="requestDetails">{`${t('message')} ${t(
                'optional'
              )}`}</label>
              <Field
                name="message"
                as="textarea"
                rows="6"
                className={errors.message && touched.message ? 'error' : ''}
                placeholder={t('messagePlaceHolder')}
              />
              {errors.message && touched.message ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.message}
                </p>
              ) : null}
            </div>
            <GoldButton type="submit" disabled={isSubmitting}>
              {t('submitRequest')}
            </GoldButton>
          </Form>
        ) : (
          <div className={styles['submitting']}>
            <Spinner />
            <p>{t('sendingRequest')}</p>
          </div>
        )
      }
    </Formik>
  );
}

export default RequestServiceForm;
