/* eslint-disable @nx/enforce-module-boundaries */
import { NavLink, useParams } from 'react-router-dom';
import Numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import styles from './unit-payment.module.scss';
import { ROUTES } from '../../../api/routes';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import ProgressBar from '../../../components/common/progress-bar/progress-bar';
import { useContext, useEffect, useRef, useState } from 'react';
import { UnitDetails } from '@orascom/api-interfaces';
import {
  GoldButton,
  Loader,
  Modal,
  NavyAnchor,
} from '@orascom/common-components';
import UnitPaymentBreakdown from '../../../components/pre-delivery/unit-payment-breakdown/unit-payment-breakdown';
import PaymentProgress from '../../../components/pre-delivery/payment-progress/payment-progress';
import { useUnitPayment } from '../hooks/use-unit-payment';
import { differenceInYears } from 'date-fns';
import { useGetMyUnits } from '../hooks/use-get-my-units';
import unitImagePlaceHolder from '../../../assets/images/uni-details.webp';
import { ServiceRequestTypeEnum } from 'apps/orascom-shopper-app/src/definitions/interfaces/service-requests.interface';
import { getCustomerId } from 'apps/orascom-shopper-app/src/utils/common.utils';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';
import { UserReservationDetailsInterface } from 'apps/orascom-shopper-app/src/definitions/interfaces/common.interface';
import { NavyButton } from 'apps/orascom-shopper-app/src/components/common/button/button';
import successIcon from '../../../assets/icons/success.svg';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';

export function UnitPayment() {
  const { t } = useTranslation();
  const unitId = useParams<{ unitId: string }>().unitId;

  const paymentBreakdownRef = useRef<null | HTMLDivElement>(null);

  const [unitInstallments, loading] = useUnitPayment(unitId);

  const userContext = useContext(USER_CONTEXT);

  const [customerId, setCustomerId] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  const handleScrollToPaymentBreakdown = () => {
    if (paymentBreakdownRef?.current) {
      paymentBreakdownRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [unitDetails, setUnitDetails] = useState<UnitDetails>();
  const [myUnits, loadingMyUnits] = useGetMyUnits();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [accountStatementLoader, setAccountStatementLoader] = useState(false);

  useEffect(() => {
    if (unitId && myUnits.length) {
      setUnitDetails(myUnits.find((unit) => unit.id.toString() === unitId));
      const foundCustomerId = getCustomerId(
        Number(unitId),
        userContext.userReservationDetails as UserReservationDetailsInterface
      );
      if (foundCustomerId) {
        setCustomerId(foundCustomerId);
      }
    }
  }, [unitId, myUnits]);

  if (loadingMyUnits || loading || !unitInstallments) {
    return <Loader />;
  }

  if (!unitInstallments?.installments?.length) {
    return (
      <div className={`${styles['unit-payment']} pre-delivery-container`}>
        <header className={styles['header']}>
          <NavLink
            to={ROUTES.Pre_Delivery_Payments.path}
            className="back-button"
          >
            <img src={backArrowIcon} alt="side-menu-icon" />
            <>{t('backToMyUnitPayments')}</>
          </NavLink>
          <div className={styles['header-details']}>
            <NavLink to={ROUTES.Pre_Delivery_Payments.path}>
              {t('unitPayments')}
            </NavLink>
            <span>/</span>
            <p>{t('paymentDetails')}</p>
          </div>
        </header>
        <h1 className="orascom__sub-title">no payments found for this unit</h1>;
      </div>
    );
  }

  const installmentsDuration = differenceInYears(
    new Date(
      unitInstallments?.installments[unitInstallments?.installments?.length - 1]
        .payment_date * 1000
    ),
    new Date(unitInstallments.installments[0]?.payment_date * 1000)
  );
  const unPaidInstallments = unitInstallments?.installments?.filter(
    (installment) => !installment.paid
  );

  const handleRequestAccountStatement = () => {
    setAccountStatementLoader(true);
    if (unitId) {
      PreDeliveryUserUtilities.submitServiceRequest(unitId, {
        customer_source_id: customerId,
        service_slug: ServiceRequestTypeEnum.ACCOUNT_STATEMENT,
        request_details: '',
      })
        .then(() => {
          setSuccessMsg(true);
        })
        .catch(() => setErrorMsg(true))
        .finally(() => {
          handleShowModal();
          setAccountStatementLoader(false);
        });
    }
  };

  return (
    <div className={`${styles['unit-payment']} pre-delivery-container`}>
      <header className={styles['header']}>
        <NavLink to={ROUTES.Pre_Delivery_Payments.path} className="back-button">
          <img src={backArrowIcon} alt="side-menu-icon" />
          <>{t('backToMyUnitPayments')}</>
        </NavLink>
        <div className={styles['header-details']}>
          <NavLink to={ROUTES.Pre_Delivery_Payments.path}>
            {t('unitPayments')}
          </NavLink>
          <span>/</span>
          <p>{t('paymentDetails')}</p>
        </div>
      </header>
      <div className={styles['details']}>
        <div className={styles['details-payments']}>
          <h1 className="orascom__sub-title">{t('paymentDetails')}</h1>
          {unPaidInstallments.length ? (
            <div className={styles['details-payments-installments']}>
              <div className={styles['details-payments-installment']}>
                <label>{t('nextInstallment')}</label>
                <p>
                  {Numeral(unPaidInstallments[0].payment_value).format('0,0')}{' '}
                  <span>{t('egp')}</span>
                </p>
              </div>
              <div className={styles['details-payments-installment']}>
                <label>{t('nextInstallmentDue')}</label>
                <p>
                  {new Date(
                    unPaidInstallments[0].payment_date * 1000
                  ).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div>
            </div>
          ) : null}
          <div className={styles['details-payments-progress']}>
            <h2>{t('installmentProgress')}</h2>
            <ProgressBar
              completed={unitInstallments.paid_installments_count}
              total={unitInstallments.total_installments_count}
            />
          </div>

          <PaymentProgress
            unitInstallments={unitInstallments}
            fullChartInfo
            handleClick={handleScrollToPaymentBreakdown}
          />
        </div>
        <div className={styles['details-info']}>
          <div className={styles['details-info-image']}>
            <img
              src={unitDetails?.cover_image ?? unitImagePlaceHolder}
              alt=""
              loading="lazy"
            />
          </div>
          <div className={styles['details-info-content']}>
            <p>
              {unitDetails?.project.destination.name} -{' '}
              {unitDetails?.project.name}
            </p>
            <h3>{unitDetails?.unit_type.name}</h3>
            <div className={styles['details-info-content-items']}>
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('unitNumber')}</label>
                <p>{unitDetails?.name}</p>
              </div>
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('unitPrice')}</label>
                <p>
                  {Numeral(unitDetails?.price).format('0,0')}{' '}
                  {unitDetails?.currency}
                </p>
              </div>
              {unitInstallments.total_maintenance_fees ? (
                <div className={styles['details-info-content-item']}>
                  <label htmlFor="">{t('maintenanceFees')}</label>
                  <p>
                    {Numeral(unitInstallments.total_maintenance_fees).format(
                      '0,0'
                    )}{' '}
                    {t('egp')}
                  </p>
                </div>
              ) : null}
              {unitInstallments.total_clubhouse_fees ? (
                <div className={styles['details-info-content-item']}>
                  <label htmlFor="">{t('clubHouseFees')}</label>
                  <p>
                    {Numeral(unitInstallments.total_clubhouse_fees).format(
                      '0,0'
                    )}{' '}
                    {t('egp')}
                  </p>
                </div>
              ) : null}
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('installmentsDuration')}</label>
                <p>
                  {installmentsDuration} {t('years')}
                </p>
              </div>
              {/* <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('purchaseDate')}</label>
                <p>
                  {new Date(
                    unitPaymentsDetails.purchase_date * 1000
                  ).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div>
              <div className={styles['details-info-content-item']}>
                <label htmlFor="">{t('deliveryYear')}</label>
                <p>
                  {t('q')}
                  {Math.floor(
                    new Date(
                      unitPaymentsDetails.delivery_year * 1000
                    ).getMonth() /
                      3 +
                      1
                  )}{' '}
                  {new Date(
                    unitPaymentsDetails.delivery_year * 1000
                  ).getFullYear()}
                </p>
              </div> */}
            </div>
          </div>
          {unitDetails?.account_statement ? (
            <div>
              <NavyAnchor
                href={unitDetails.account_statement}
                download={`${unitDetails.name} account statement`}
                target="_blank"
                rel="noreferrer"
                className={styles['account-statment-btn']}
              >
                {t('downloadAccountStatement')}
              </NavyAnchor>
            </div>
          ) : accountStatementLoader ? (
            <div className={styles['request-loader']}>
              <Loader />
            </div>
          ) : (
            <NavyButton
              type="button"
              onClick={handleRequestAccountStatement}
              className={styles['account-statment-btn']}
            >
              {t('requestAccountStatement')}
            </NavyButton>
          )}
        </div>
      </div>

      <UnitPaymentBreakdown
        ref={paymentBreakdownRef}
        installments={
          unitInstallments.reservationInstallments &&
          unitInstallments.deliveryInstallments
            ? unitInstallments.reservationInstallments.concat(
                unitInstallments.installments,
                unitInstallments.deliveryInstallments
              )
            : unitInstallments.installments
        }
        unitCountry={unitDetails?.project.destination.country.slug}
        // paymentPlanBreakdown={unitPaymentsDetails.payment_plan_breakdown}
      />

      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={t('accountStatementRequest')}
      >
        {successMsg && (
          <div>
            <div className={styles['success']}>
              <p className={styles['success-title']}>
                <img src={successIcon} role="presentation" alt="" />
                <span>{t('formSubmitSuccessTitle')}</span>
              </p>
              <p className={styles['success-message']}>
                {t('successAccountStatement')}
              </p>
            </div>
            <GoldButton onClick={handleCloseModal}>{t('done')}</GoldButton>
          </div>
        )}
        {errorMsg && (
          <div>
            <div className={styles['error']}>
              <p className={styles['error-title']}>
                <img src={errorSubmitIcon} role="presentation" alt="" />
                <span>{t('formSubmitErrorTitle')}</span>
              </p>
              <p className={styles['error-message']}>
                {t('failedAccountStatement')}
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default UnitPayment;
