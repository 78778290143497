import { CurrencyContext } from '@orascom/utils';
import { useContext } from 'react';

const useCurrencyConverter = () => {
  const {
    rates,
    currency: selectedCurrency,
    isLoading,
  } = useContext(CurrencyContext);

  function convertPrice(
    price: number,
    currency: string,
    targetCurrency: string
  ) {
    const isSameCurrency = targetCurrency === currency;
    if (isLoading || !rates || isSameCurrency) return { price, currency };

    const secondCurrency =
      selectedCurrency === targetCurrency ? currency : targetCurrency;

    const rateKey = `${selectedCurrency}${secondCurrency}`;
    const rate = rates[rateKey];

    // just in case
    if (!rate) {
      console.error(`Conversion rate for ${rateKey} not found.`);
      return { price, currency };
    }

    const convertedPrice =
      selectedCurrency === targetCurrency ? price / rate : price * rate;
    return { price: convertedPrice, currency: targetCurrency };
  }

  function convertToSelectedCurrency(
    price: number,
    currency: string
  ): ReturnType<typeof convertPrice> {
    return convertPrice(price, currency, selectedCurrency);
  }

  return {
    convertToSelectedCurrency,
    convertPrice,
    rates,
    selectedCurrency,
    isLoading,
  };
};

export default useCurrencyConverter;
